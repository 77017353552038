const stagingAddresses = {
    cycle: "0x2e95D5493Dc74483E2D449AB9241acB9227C89f5",
    cycleLP: "0x6BFD7bbed1Ea89314e49e869FDA68083C1D22f36",
    distributor: "0xa06f589FDf05788298A1E7E8CfF7aF53F6b847F0",
    harvestProcessor: "0xb9EA467CC54fE7F7eEe6271Aef9eb88f52e9b615",
    coreRewards: "0xB36B2A1dD4FF1052d0cb5Ee0B12dA21564cF879A",
    avaxRewards: "0xb2a4B0dB908cBb27e7822cBEa111b19c75AA6499",
    strategyVariables: "0xB18dCb184793be39550C6a055338286DE94c755D",
    callFeeAmount: {
        "PNG": "0xF307d8744CF276CfD7868eFd81544783071684DC",
        "GDL": "0xBF05d93cA2D42250C7a059B24a5270f527B309E0",
        "PEFI": "0x2cA907D2a71c5479D657916dc23d8aeaaE77655e",
        "OLIVE": "0xE88cA05636e41D7Cf95DC41441f0d7ec59f94f08"
    },
    priceHelper: "0xc88486F7a8273e3bCB8DeEfe7B06508fFE6C0aab",
    coreRewardsTVL: "0x54172059467cb098d3b71F1c9C5a2ebf997C014e"
}

const productionAddresses = {
    cycle: "0x81440C939f2C1E34fc7048E518a637205A632a74",
    cycleLP: "0x51486D916A273bEA3AE1303fCa20A76B17bE1ECD",
    distributionProxy: "0x9F0631F10D607Dd80696A067dF2150da683acA81",
    distributor: "0x96e9778511cC9F8E5d35652497248131D235005A",
    harvestProcessor: "0x5DA68E32c46c026732564C4E7689E712d6E6a57C",
    coreRewards: "0xE006716Ae6cAA486d77084C1cca1428fb99c877B",
    avaxRewards: "0x6140D3ED2426cbB24f07D884106D9018d49d9101",
    strategyVariables: "0xB18dCb184793be39550C6a055338286DE94c755D",
    callFeeAmount: {
        "PNG": "0xF307d8744CF276CfD7868eFd81544783071684DC",
        "GDL": "0xBF05d93cA2D42250C7a059B24a5270f527B309E0",
        "PEFI": "0x2cA907D2a71c5479D657916dc23d8aeaaE77655e",
        "OLIVE": "0xE88cA05636e41D7Cf95DC41441f0d7ec59f94f08",
        "BAG": "0xcF682AccEbfcB85f56B5EC40758D3D38562C992B",
        "AVE": "0x798d9C12b70802de188F0a00C80AED4EDBc32C4d",
        "JOE": "0xec3EAec872Cbc05894a4C63386b2375F12b7320A",
        "BLUE": "0x102e5E654087A511E23016fFF120a27F2e5EE207",
        "AVAX": "0x6b6E82626C05c2c242D9825bAaE267e6236492f5",
        "LYD": "0x4901628111f7717E2b8F400F2D32a339F7F64fFb"
    },
    priceHelper: "0xc88486F7a8273e3bCB8DeEfe7B06508fFE6C0aab",
    coreRewardsTVL: "0x54172059467cb098d3b71F1c9C5a2ebf997C014e",
    avaxRewardsTVL: "0xc6343D4B127735b717F7F50Dd16394F63e3E4cDe",
    cycleToAvax: "0xd94a4EEe09D244ccd63F8219B65A4feA328b17b1",
    coreRewardsLPtoAVAX: "0x038a9C02b683542c4236A75c6BD4D57D144dD122",
    lpBreakdown: "0x032C3DfEfFbbC619C1aDD1dF69eF070d7B4da6d0",
    xCycle: "0x3b2EcFD19dC9Ca35097F80fD92e812a53c180CD1"
}

export default productionAddresses;
