import React, { useState } from 'react';
import styled from 'styled-components';
import StatusCard from './status/StatusCard';

const Container = styled.div`

`;

const Cycle = () => {

    return (
        <Container>
            <StatusCard />
        </Container>
    );
}

export default Cycle;
